<template>
  <div class="spinner-container subscribe_layout">
    <div class="spinner" v-if="isLoading"></div>
  </div>
</template>

<script>
import router from '../../router'
import ApiService from '../../services/api'
import AlertServie from '../../services/errors'
import Vue from 'vue'

export default {
  data () {
    return {
      isLoading: true,
      msisdn: null // Store the token retrieved from URL
    }
  },
  methods: {
    handleCheckUserStatus (phoneNumber) {
      const self = this
      this.checkUserStatusInterval = setInterval(async () => {
        const response = await ApiService.checkUserStatus(phoneNumber)
        if (response && response.status === 200) {
          if (response.data.status === 2 || response.data.status === 1) {
            localStorage.setItem('highFitToken', response.data.access_token)
            clearInterval(self.checkUserStatusInterval)
            ApiService.headers = {
              Authorization: 'Bearer ' + response.data.access_token,
              lang: localStorage.getItem('lang')
            }
            this.$store.commit('setPoints', response.data.user.points)
            self.$router.push('/')
            Vue.$forceUpdate()
          } else if (response.data.status === 3) {
            clearInterval(self.checkUserStatusInterval)
          }
          AlertServie.info(response.data.msg)
        } else {
          clearInterval(self.checkUserStatusInterval)
          AlertServie.info(response.data.msg)
        }
      }, 5000)
    }
  },
  created () {
    // Check if token exists
    if (this.$route.query.msisdn) {
      this.msisdn = this.$route.query.msisdn
      this.handleCheckUserStatus(this.msisdn)
    } else {
      AlertServie.info('Redirection Failed')
      router.push('/login')
    }
  }
}
</script>

<style scoped>
.subscribe_layout {
  background-image: url('../../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;

  @media (max-width: 768px) {
    background-image: url('../../assets/images/subscribe/bg_mobile.png');
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center the spinner */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 0.5s linear infinite;
}

/* Keyframe for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
